import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Select from "react-select"
import { ToastContainer, toast } from 'react-toastify';

import { AiState } from "./AiState";
import { AiTrainingResults } from "./AiTrainingResults";
import { DatasetType } from "./DatasetType";
import { SignLanguageViewObject } from "../../../viewobjects/SignLanguageViewObject";

import { setAiStates } from "../../../features/aistates/aistatesSlice";
import { setDatasetTypes } from "../../../features/datasettypes/datasettypesSlice";
import { setSignLanguages } from "../../../features/signlanguages/signlanguagesSlice";

import "./aitrainings.css";

export function Aitrainings() {

	const username = useSelector(state => state.usermangement.username);
	const password = useSelector(state => state.usermangement.password);
	const datasettypesLoaded = useSelector(state => state.datasettypes.loaded);
	const aistatesLoaded = useSelector(state => state.aistates.loaded);
	const signLanguagesLoaded = useSelector(state => state.signlanguages.loaded)

	const dispatch = useDispatch();

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [optionsLanguage, setOptionsLanguage] = useState([]);
	const [optionsDataset, setOptionsDataset] = useState([]);

	const datasets = useSelector(state => state.datasettypes.content);
	const languages = useSelector(state => state.signlanguages.content);
	const trainingstypes = [
		{value: "video", label: "video"}, 
		{value: "skeleton", label: "skeleton"}
	];

	const setDatasets = useCallback((datasets) => {

		let datasetsForSelect = [];
		let i = 0;

		for (i = 0; i < datasets.length; i++) {
			datasetsForSelect.push({ value: datasets[i].id, label: datasets[i].name });
		}

		setOptionsDataset(datasetsForSelect);

	}, [setOptionsDataset]);

	const setLanguages = useCallback((languages) => {

		let languagesForSelect = [];
		let i = 0;

		for (i = 0; i < languages.length; i++) {
			languagesForSelect.push({ value: languages[i].id, label: languages[i].name });
		}

		setOptionsLanguage(languagesForSelect);

	}, [setOptionsLanguage]);

	const loadData = useCallback((requestOptions) => {

		fetch("https://cobtras.com/api/v1/trainings", requestOptions)
			.then(response => response.json())
			.then((actualData) => {
				setData(actualData);
				setError(null);
			})
			.catch((err) => {
				setError(err.message);
				setData(null);
			})
			.finally(() => {
				setLoading(false);
			});

	}, [setLoading, setData, setError]);

	const [newTraining, setNewTraining] = useState({
		signlanguage: 0,
		dataset: 0,
		learningrate: 0.1,
		gamma: 0.1,
		epochs: 25,
		resumeepoch: 0,
		snapshotinterval: 1,
		test: 1,
		testinterval: 1,
		type: "",
		width: 2880,
		height: 1620,
		cleanbackground: 1,
		cropvideo: 1
	});

	const getMyHeaders = useCallback(() => {

		let returnValue = new Headers();
		returnValue.append("Content-Type", "application/json");

		return returnValue;

	}, []);

	const getRaw = useCallback(() => {

		return JSON.stringify({
			"mailaddress": username,
			"password": password
		});

	}, [username, password]);

	var myHeaders = useMemo(() => getMyHeaders(), [getMyHeaders]);
	var raw = useMemo(() => getRaw(), [getRaw]);

	const getRequestOptions = useCallback(() => {

		let returnValue = {

			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'

		};

		return returnValue;

	}, [raw, myHeaders]);

	var requestOptions = useMemo(() => getRequestOptions(), [getRequestOptions]);

	useEffect(() => {

		document.title = 'Cobtras - AI Trainings';

		setDatasets(datasets);
		setLanguages(languages);

		if (!datasettypesLoaded) {

			fetch("https://cobtras.com/api/v1/datasettypes", requestOptions)
				.then(response => response.json())
				.then((actualData) => {
					dispatch(setDatasetTypes(actualData));
					setError(null);
					setDatasets(actualData);
				})
				.catch((err) => {
					setError(err.message);
					setData(null);
				})

		}

		if (!aistatesLoaded) {

			fetch("https://cobtras.com/api/v1/trainingstates", requestOptions)
				.then(response => response.json())
				.then((actualData) => {
					dispatch(setAiStates(actualData));
				})
				.catch((err) => {
					setError(err.message);
					setData(null);
				})

		}

		if (!signLanguagesLoaded) {

			fetch("https://cobtras.com/api/v1/signlanguages", requestOptions)
				.then(response => response.text())
				.then((actualData) => {

					let languages = JSON.parse(actualData);

					setSignLanguages(languages);
					setLanguages(languages);

				})
				.catch((err) => {
					setError(err.message);
					setData(null);
				});

		}

		loadData(requestOptions);

	}, [aistatesLoaded, datasettypesLoaded, dispatch, password, signLanguagesLoaded, username, datasets, languages, requestOptions, loading, error,
		setData, setLoading, setError, setLanguages, setDatasets, loadData]);

	const submit = e => {

		e.preventDefault();

		const toastid = toast.loading('Save new training ...');

		var sendString1 = raw;
		sendString1 = sendString1.substring(0, sendString1.length - 1);
		var sendString2 = JSON.stringify(newTraining);
		sendString2 = sendString2.substring(1);
		var sendString = sendString1 + "," + sendString2;

		fetch('https://cobtras.com/api/v1/settraining', {
			method: 'POST',
			body: sendString,
			headers: myHeaders,
			redirect: 'follow'
		})
			.then(res => res.text())
			.then(response => {

				if (response === "+ OK") {

					setData(null);

					loadData(requestOptions);

					toast.update(toastid, { render: "New Training saved", type: "success", isLoading: false, autoClose: 5000 });

				} else {

					console.log("error: " + response);

					toast.update(toastid, { render: response, type: "error", isLoading: false, autoClose: 5000 });

				}

			});
	}

	return (
		<div class="clearfix">
			<div class="detail">
				<h1>AI-trainings</h1>
				{loading && <div>Loading data...</div>}
				{error && (
					<div>{`There is a problem fetching the post data - ${error}`}</div>
				)}
				{data &&
					<>
						<h2>New Training</h2>
						<form onSubmit={submit}>
							<table>
								<thead>
									<tr>
										<th>Sprache</th>
										<th>
											{newTraining.signlanguage !== 0 &&
												<>Datenset</>
											}
										</th>
										<th>
											{newTraining.dataset !== 0 &&
												<>Lernrate</>
											}
										</th>
										<th>
											{newTraining.dataset !== 0 &&
												<>Gamma</>
											}
										</th>
										<th>
											{newTraining.dataset !== 0 &&
												<>Epochen</>
											}
										</th>
										<th>
											{newTraining.dataset !== 0 &&
												<>Ab Epoche</>
											}
										</th>
										<th>
											{newTraining.dataset !== 0 &&
												<>Snapshot Intervall</>
											}
										</th>
										<th>
											{newTraining.dataset !== 0 &&
												<>Test</>
											}
										</th>
										<th>
											{newTraining.dataset !== 0 &&
												<>Test Interval	</>
											}
										</th>
										<th>
											{newTraining.dataset !== 0 &&
												<>Erkennungsart</>
											}
										</th>
										{newTraining.type === "video" &&
											<>
												<th>Breite</th>
												<th>H&ouml;he</th>
												<th>Hintergrund entfernen</th>
												<th>Video beschneiden</th>
											</>
										}
									</tr>
								</thead>
								<tbody>
									<tr>
										<td><Select name="language" placeholder="Choose a sign language" isSearchable="true" isLoading={optionsLanguage.length === 0 ? true : false} options={optionsLanguage} onChange={e => setNewTraining({ ...newTraining, signlanguage: e.value })}></Select></td>
										<td>
											{newTraining.signlanguage !== 0 &&
												<Select name="dataset" placeholder="Choose a dataset" isSearchable="true" isLoading={optionsDataset.length === 0 ? true : false} options={optionsDataset} onChange={e => setNewTraining({ ...newTraining, dataset: e.value })}></Select>
											}
										</td>
										<td>
											{newTraining.dataset !== 0 &&
												<input name="newTraining[learningrate]" type="number" value={newTraining.learningrate} step="0.001" onChange={e => setNewTraining({ ...newTraining, learningrate: parseFloat(e.target.value) })} />
											}
										</td>
										<td>
											{newTraining.dataset !== 0 &&
												<input name="newTraining[gamma]" type="number" value={newTraining.gamma} step="0.001" onChange={e => setNewTraining({ ...newTraining, gamma: parseFloat(e.target.value) })} />
											}
										</td>
										<td>
											{newTraining.dataset !== 0 &&
												<input name="newTraining[epochs]" type="number" step="1" value={newTraining.epochs} size="4" onChange={e => setNewTraining({ ...newTraining, epochs: parseInt(e.target.value) })} />
											}
										</td>
										<td>
											{newTraining.dataset !== 0 &&
												<input name="newTraining[resumeepoch]" value={newTraining.resumeepoch} size="3" readonly />
											}
										</td>
										<td>
											{newTraining.dataset !== 0 &&
												<input name="newTraining[snapshotinterval]" step="1" value={newTraining.snapshotinterval} size="2" onChange={e => setNewTraining({ ...newTraining, snapshotinterval: parseInt(e.target.value) })} />
											}
										</td>
										<td>
											{newTraining.dataset !== 0 &&
												<select name="test" defaultValue={newTraining.test} onChange={e => setNewTraining({ ...newTraining, test: e.target.value })}><option value="1">Ja</option><option value="0">Nein</option></select>
											}
										</td>
										<td>
											{newTraining.dataset !== 0 &&
												<input name="newTraining[testinterval]" step="1" value={newTraining.testinterval} size="2" onChange={e => setNewTraining({ ...newTraining, testinterval: parseInt(e.target.value) })} />
											}
										</td>
										<td>
											{newTraining.dataset !== 0 &&
												<Select name="trainingsType" placeholder="Choose a recognition type"  options={trainingstypes} onChange={e => setNewTraining({ ...newTraining, type: e.value })} />
											}
										</td>
										{newTraining.type === "video" &&
											<>
												<td><input name="newTraining[width]" value={newTraining.width} size="4" onChange={e => setNewTraining({ ...newTraining, width: parseInt(e.target.value) })} /></td>
												<td><input name="newTraining[height]" value={newTraining.height} size="4" onChange={e => setNewTraining({ ...newTraining, height: parseInt(e.target.value) })} /></td>
												<td><select name="cleanbackground" defaultValue={newTraining.cleanbackground} onChange={e => setNewTraining({ ...newTraining, cleanbackground: e.target.value })}><option value="1">Ja</option><option value="0">Nein</option></select></td>
												<td><select name="cropvideo" defaultValue={newTraining.cropvideo} onChange={e => setNewTraining({ ...newTraining, cropvideo: e.target.value })}><option value="1">Ja</option><option value="0">Nein</option></select></td>
											</>
										}
									</tr>
								</tbody>
							</table>
							<p>
								<input type="submit" value="Save new training" />
							</p>
						</form>
						<h2>Your Trainings</h2>
						<table class="trainingstable">
							<thead>
								<tr key="h"><th>Nummer</th><th>Sprache</th><th>Datenset</th><th>Klassen</th><th>Variablen</th><th>Status</th><th>Results</th></tr>
							</thead>
							<tbody>
								{data.map(({ id, language, dataset, classes, state, learning_rate, gamma, epochs, resume_epoche, snapshot_interval, test, test_interval, type, video_width, video_height, clean_background, crop_video }) => (
									<tr key={id}>
										<td>{id}</td>
										<td><SignLanguageViewObject id={language} /></td>
										<td><DatasetType id={dataset} /></td>
										<td>{classes}</td>
										<td>
											Lernrate: {learning_rate}<br />
											Gamma-Wert: {gamma}<br />
											Epochen: {epochs}<br />
											Ab Epoche: {resume_epoche}<br />
											Snapshot-Interval: {snapshot_interval}<br />
											{test ? `Testinterval: ${test_interval}` : 'Keine Tests'}<br />
											{type === "skeleton" &&
												<>Trainingsart Skeleton</>
											}
											{type === "video" &&
												<>
													Trainingsart: Video<br />
													Aufl&ouml;sung: {video_width} x {video_height}
													{clean_background === 0 &&
														<>
															<br />Hintergrund wurde entfernt
														</>
													}
													{crop_video === 0 &&
														<>
															<br />Video wird beschnitten
														</>
													}
												</>
											}
										</td>
										<td><AiState state={state} /></td>
										<td><AiTrainingResults id={id} state={state} /></td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				}
				<ToastContainer />
			</div>
		</div>
	);

}