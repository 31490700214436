import React from 'react';
import speaking from '../../../img/public-speaking.jpg';

class News extends React.Component{
	
	constructor(props){
		super(props);
		this.state = {render: true};
	}
	
	componentDidMount(){
    	document.title = "Cobtras - News"
  	}
	
	render() {
		return (
			<div class="clearfix">
				<div class="detail">
					<div id='headline'>
						<h1 className='headlinetext'>News</h1>
						<img src={speaking} className='headlinepic' alt='Mikrofon auf Bühne' />
					</div>
					<h2>Premiere auf dem RuhrSummit: Vorstellung des Cobtras Prototyps</h2>
					<p>Wir freuen uns, mitteilen zu können, dass wir auf dem diesjährigen RuhrSummit den Prototypen von Cobtras, unserem innovativen, interaktiven Vokabeltrainer für Gebärden, erstmalig vorgestellt haben. Cobtras nutzt modernste computerunterstützte Technologien, um das Erlernen von Gebärdenvokabular effizienter und zugänglicher zu gestalten. Die Präsentation auf dem RuhrSummit war ein großer Erfolg und wir sind begeistert von dem positiven Feedback, das wir erhalten haben. Dieser Prototyp stellt einen bedeutenden Meilenstein für die Inklusion von Gehörlosen im beruflichen und privaten Umfeld dar.</p>
            		<p>Trotz aller Widrigkeiten freuen wir uns, einen positiven Beitrag zur Verbesserung der Lebensqualität von Gehörlosen leisten zu können und sind stolz auf den Impact, den Cobtras bringen kann.</p>
            		<h2>Cobtras nimmt an greenhouse.ruhr 2024 teil</h2>
            		<p>Wir freuen uns, bekannt geben zu können, dass unser Projekt Cobtras erfolgreich für das renommierte <a href='https://greenhouse.ruhr' target='_blank' rel="noreferrer">greenhouse.ruhr</a>-Programm ausgewählt wurde. Dieses Förderprogramm unterstützt innovative Start-ups und Projekte, die nachhaltige und zukunftsorientierte Lösungen entwickeln.</p>
					<h3>Was ist greenhouse.ruhr?</h3>
					<p><a href='https://greenhouse.ruhr' target='_blank' rel="noreferrer">greenhouse.ruhr</a> ist eine Initiative der Wirschaftsförderung Dormund, die es sich zum Ziel gesetzt hat, vielversprechende Start-ups aus dem Bereich Social Entrepreneurship zu fördern. Durch maßgeschneiderte Workshops, individuelle Coaching und ein starkes Netzwerk bietet das Programm den Teilnehmern optimale Bedingungen, um ihre Projekte weiterzuentwickeln und auf die nächste Stufe zu heben.</p>
					<h3>Was bedeutet das für Cobtras?</h3>
					<p>Die Teilnahme am greenhouse.ruhr-Programm ist für Cobtras eine großartige Gelegenheit, unsere Vision von der Entwicklung eines interaktiven Vokabeltrainer für Gebärden voranzutreiben. Wir werden Zugang zu wertvollen Ressourcen, Expertenwissen und einem inspirierenden Umfeld haben, das uns dabei unterstützt, unser volles Potenzial auszuschöpfen.</p>
					<h3>Wir danken für die Unterstützung</h3>
					<p>Ein herzlicher Dank geht an das gesamte Social Entrepreneurship-Team der Wirtschaftsförderung Dortmun für diese großartige Möglichkeit. Wir sind gespannt auf die kommenden Herausforderungen und Chancen und sind fest entschlossen, das Beste daraus zu machen.</p>
					<p>&nbsp;</p>
					<p>
						Leider ist der Text für diese Seite noch nicht vollständig. Wir bitten um Verständnis.
            		</p>
				</div>
			</div>
		);
  }
  
}

export default News;